import React from 'react';

import { useTranslation } from '~/hooks/useTranslation';
import ErrorPage from '~/layouts/ErrorPage';

export function RootErrorPage() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      title={t('unknownErrorPage.title')}
      message={t('unknownErrorPage.message')}
      buttonText={t('unknownErrorPage.button')}
      onClick={() => window.location.reload()}
    />
  );
}
