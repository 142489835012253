import React from 'react';
import { initSnowplowTracker } from '@ev/snowplow-library';
import ReactDOM from 'react-dom/client';

import { GOmeApp } from '~/pages/GOmeApp';

import '~/styles/globals.css';
import '~/styles/carousel.css';
import '~/styles/imageGallery.css';
import '~/styles/productFruits.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).process = { env: import.meta.env.VITE_ENV }; // Vite no longer uses process but some libs crash without this

initSnowplowTracker({
  applicationId: 'go3-toolbox-next',
  hasUserAcceptedAgreement: true,
  isProd: window.location.host.includes('engelvoelkers.com'),
  initialTrackPageViewEvent: false,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <GOmeApp />
  </React.StrictMode>,
);
