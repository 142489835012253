import React from 'react';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';

import { locales } from '~/consts/locale';
import { NotFoundPage } from '~/layouts/NotFoundPage';
import { AgentRoot } from '~/pages/AgentRoot';
import { RootErrorPage } from '~/pages/RootErrorPage';
import { pages } from '~/pages/routes';

function createLanguageRoutes(children: RouteObject[]): RouteObject[] {
  return [
    ...locales.map((lang) => ({
      path: lang,
      children,
    })),
    ...children,
  ];
}

const router = createBrowserRouter([
  {
    path: '',
    element: <AgentRoot />,
    errorElement: <RootErrorPage />,
    children: createLanguageRoutes(pages),
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
