import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';

import { configureApp } from '~/configs';
import { KeycloakProvider } from '~/hooks/useSession';
import { TranslationProvider } from '~/hooks/useTranslation';
import { Router } from '~/pages/Router';
import { NotificationProvider } from '~/providers/standalone/NotificationProvider';
import { UIContextProvider } from '~/providers/standalone/UiContextProvider';
import { muiCustomTheme } from '~/theme';

const config = configureApp();

export function GOmeApp() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={muiCustomTheme}>
        <CssBaseline />
        <TranslationProvider>
          <KeycloakProvider>
            <UIContextProvider>
              <NotificationProvider>
                <QueryClientProvider client={config.reactQueryClient}>
                  <Router />
                </QueryClientProvider>
              </NotificationProvider>
            </UIContextProvider>
          </KeycloakProvider>
        </TranslationProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}
