import React, { useEffect, useRef, useState } from 'react';
import { KeycloakContext, ParsedJwtToken, setupKeycloak } from '@ev/eva-container-api';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import { Trans, useTranslation } from 'react-i18next';

import ErrorPage from '~/layouts/ErrorPage';
import sentry from '~/utils/sentry';

const keycloak = setupKeycloak({
  url: import.meta.env.VITE_KEYCLOAK_URL,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
});

export function KeycloakProvider({ children }: React.PropsWithChildren) {
  const { t } = useTranslation();

  const authenticating = useRef(false);
  const [hasError, setHasError] = useState(false);
  const [parsedToken, setParsedToken] = useState<ParsedJwtToken>();

  useEffect(() => {
    if (!authenticating.current) {
      authenticating.current = true;
      keycloak
        .init({ onLoad: 'login-required', checkLoginIframe: false })
        .then(() => {
          setParsedToken(keycloak.tokenParsed as ParsedJwtToken);
        })
        .catch((error) => {
          setHasError(true);
          sentry.logError(error as Error);
        });

      keycloak.onAuthError = (error) => {
        setHasError(true);
        if (error) {
          sentry.logError(error.error, { extra: { description: error.error_description } });
        } else {
          // The type is lie onAuthError can be called without an error
          sentry.logError('Unknown keycloak error');
        }
      };
      keycloak.onAuthRefreshSuccess = () => {
        setParsedToken(keycloak.tokenParsed as ParsedJwtToken);
      };
    }
  }, []);

  if (hasError) {
    return (
      <ErrorPage
        title={t('nextAuth.error.title')}
        message={
          <Trans
            t={t}
            i18nKey="nextAuth.error.message"
            components={{
              a: <Link href="mailto:servicedesk@engelvoelkers.com" />,
            }}
          />
        }
        buttonText={t('nextAuth.error.button.signin')}
        onClick={() => window.location.reload()}
      />
    );
  }

  if (!parsedToken) {
    return <LinearProgress />;
  }

  return <KeycloakContext.Provider value={parsedToken}>{children}</KeycloakContext.Provider>;
}
